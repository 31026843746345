import React, { useRef, useCallback, useState, useContext } from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Nav from './Nav';
import tw from 'twin.macro'; //eslint-disable-line
import styled from 'styled-components';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { useDropzone } from 'react-dropzone';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { MainPageContext } from 'App';

const Container = tw.div`relative flex-1`;
const Content = tw.div`max-w-screen-xl mx-auto`;

const FormContainer = styled.div`
  ${tw`  text-gray-700 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-800 text-base font-medium tracking-wide border-b-2 py-2 hocus:border-gray-800 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-600`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 text-gray-100 bg-primary-800 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:text-gray-300 hover:bg-primary-900 hocus:-translate-y-px hocus:shadow-xl`;

export default ({ headingText = 'Terms And Condition' }) => {
  const context = useContext(MainPageContext)
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: 'Upload dataset successfully!',
    severity: 'success',
  });
  const [form, setForm] = useState({
    title: '',
    author: '',
    email: '',
    demographic: '',
    historical: '',
    geographic: '',
    other: '',
    description: '',
    image: '',
    price: '',
  });
  const onDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles[0]);
    var file = acceptedFiles[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      // The file's text will be printed here
      setForm((oldState) => {
        return {
          ...oldState,
          image: event.target.result,
        };
      });
    };

    reader.readAsDataURL(file);
  }, []);
  function onError(e) {
    setAlert((oldState) => {
      return {
        ...oldState,
        open: true,
        severity: 'error',
        message:
          'Only 1 images in format of *.jpeg or *.jpg or *.png is allowed.',
      };
    });
  }
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles: 1,
    onDropAccepted: onDrop,
    onDropRejected: onError,
    maxSize: 80000,
  });

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (!handleCheck()) {
      setLoading(false);

      return;
    }

    var baseUrl = `http://greenieweb.cyclic.app/auth/addData`;
    try {
      let result = await fetch(baseUrl, {
        method: 'POST',
        body: JSON.stringify({
          title: form.title,
          author: form.author,
          usage: form.demographic,
          time: form.historical,
          location: form.geographic,
          star: form.other,
          description: form.description,
          price: Number(form.price),
          email: form.email,
          // image: form.image,
          owner: context.user._id
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      await result.json();
    } catch (e) {
      setAlert((oldState) => {
        return {
          ...oldState,
          open: true,
          severity: 'error',
          message: 'Fail to upload!',
        };
      });
      setLoading(false);
      return;
    }

    refresh();
    console.log(form);
  }

  function refresh() {
    setLoading(false);
    acceptedFiles.pop();
    setForm({
      title: '',
      author: '',
      demographic: '',
      historical: '',
      geographic: '',
      other: '',
      description: '',
      price: '',
      email: '',
      image: '',
    });
  }

  function handleCheck() {
    console.log(form);
    if (
      form.title === '' ||
      form.author === '' ||
      form.description === '' ||
      // form.image === '' ||
      form.email === '' ||
      form.price === ''
    ) {
      setAlert((oldState) => {
        return {
          ...oldState,
          open: true,
          severity: 'error',
          message: 'Please fill out the form.',
        };
      });
      return false;
    }

    let count = 0;
    if (form.demographic === '') count++;
    if (form.historical === '') count++;
    if (form.geographic === '') count++;
    if (form.other === '') count++;
    console.log(count);
    if (count > 3) {
      setAlert((oldState) => {
        return {
          ...oldState,
          open: true,
          severity: 'error',
          message: 'Please fill out 3 of the statistics.',
        };
      });
      return false;
    }
    if (isNaN(form.price)) {
      setAlert((oldState) => {
        return {
          ...oldState,
          open: true,
          severity: 'error',
          message: 'Price must be a number.',
        };
      });
      return false;
    }
    return true;
  }
  const handleClose = (event, reason) => {
    setAlert((oldState) => {
      return {
        ...oldState,
        open: false,
      };
    });
    if (reason === 'clickaway') {
      return;
    }
  };
  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  return (
    <AnimationRevealPage>
      <Backdrop
        sx={{ color: '#fa8e30', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={loading}
      >
        <CircularProgress color="success" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      <div tw="flex flex-row gap-16">
        <Nav />
        <Container>
          <Content>
            <FormContainer>
              <div tw="mx-auto max-w-4xl">
                <h2>List datasets for sales</h2>

                <form ref={formRef} onSubmit={handleSubmit}>
                  <p>*Required.</p>
                  <p>**Only 3 Statistics are required.</p>

                  <TwoColumn>
                    <Column>
                      <InputContainer>
                        <Label htmlFor="title-input">Title*</Label>
                        <Input
                          id="title-input"
                          type="text"
                          name="title"
                          placeholder="Title of the dataset."
                          value={form.title}
                          onChange={handleChange}
                        />
                      </InputContainer>
                      <InputContainer>
                        <Label htmlFor="email-input">Contact Email*</Label>
                        <Input
                          id="email-input"
                          type="text"
                          name="email"
                          placeholder="Contact email for the dataset."
                          value={form.email}
                          onChange={handleChange}
                        />
                      </InputContainer>
                      <InputContainer>
                        <Label htmlFor="demographic-input">
                          Demographic Statistics**
                        </Label>
                        <Input
                          value={form.demographic}
                          onChange={handleChange}
                          id="demographic-input"
                          type="text"
                          name="demographic"
                          placeholder="Related to structure of population."
                        />
                      </InputContainer>
                      <InputContainer>
                        <Label htmlFor="historical-input">
                          Historical Statistics**
                        </Label>
                        <Input
                          value={form.historical}
                          onChange={handleChange}
                          id="historical-input"
                          type="text"
                          name="historical"
                          placeholder="Related to its history."
                        />
                      </InputContainer>
                      <InputContainer>
                        <Label htmlFor="geographic-input">
                          Geographic Statistics**
                        </Label>
                        <Input
                          id="geographic-input"
                          type="text"
                          value={form.geographic}
                          onChange={handleChange}
                          name="geographic"
                          placeholder="Location that the it target."
                        />
                      </InputContainer>
                    </Column>
                    <Column>
                      <InputContainer>
                        <Label htmlFor="author-input">Author*</Label>
                        <Input
                          id="author-input"
                          type="text"
                          name="author"
                          value={form.author}
                          onChange={handleChange}
                          placeholder="Author of the dataset."
                        />
                      </InputContainer>

                      <InputContainer>
                        <Label htmlFor="other-input">Other Statistics**</Label>
                        <Input
                          id="other-input"
                          type="text"
                          value={form.other}
                          onChange={handleChange}
                          name="other"
                          placeholder="Other statistics."
                        />
                      </InputContainer>
                      <InputContainer tw="flex-1">
                        <Label htmlFor="description-input">Description*</Label>
                        <TextArea
                          id="description-input"
                          value={form.description}
                          onChange={handleChange}
                          name="description"
                          placeholder="Detailed description about the datatset."
                        />
                      </InputContainer>
                      <InputContainer>
                        <Label htmlFor="price-input">Price*</Label>
                        <Input
                          id="price-input"
                          type="text"
                          name="price"
                          value={form.price}
                          onChange={handleChange}
                          placeholder="Displayed price of the dataset."
                        />
                      </InputContainer>
                    </Column>
                  </TwoColumn>
                  <p tw="my-3 font-bold">Preview Image*</p>

                  <section tw="border-dashed border-2 rounded-sm border-gray-400 cursor-pointer">
                    <div
                      {...getRootProps({ className: 'dropzone' })}
                      tw="flex text-gray-600 justify-center text-center flex-col items-center min-h-[20rem]"
                    >
                      <input {...getInputProps()} />
                      {acceptedFiles.length > 0 ? (
                        <aside>
                          <div tw="flex justify-center my-8">
                            <img src={form.image} alt='' tw="max-h-[30rem]" />
                          </div>
                        </aside>
                      ) : (
                        <>
                          <p>
                            Drag & drop a 80Kb image here, or click to select an
                            image
                          </p>
                          <p>(Only *.jpeg and *.png images will be accepted)</p>
                        </>
                      )}
                    </div>
                  </section>
                  <SubmitButton type="submit" value="Submit">
                    Upload
                  </SubmitButton>
                </form>
              </div>
            </FormContainer>
          </Content>
        </Container>
      </div>
    </AnimationRevealPage>
  );
};
