import React from 'react';
import GlobalStyles from 'styles/GlobalStyles';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { useState } from 'react';
import SaaSProductLandingPage from 'demos/SaaSProductLandingPage.js';

import ThankYouPage from 'ThankYouPage.js';
import BlogIndexPage from 'pages/BlogIndex.js';
import Login from 'pages/Login';
import Account from 'pages/Account';
import Signup from 'pages/Signup';
import TermsOfService from 'pages/TermsOfService';
import ContactUs from 'pages/ContactUs';
import ProtectedRoute from 'pages/ProtectedRoute';
import Upload from 'pages/Upload';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Edit from 'pages/Edit';

export const MainPageContext = React.createContext({});

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  let [user, setUser] = useState({
    "_id": "65864e47fdeadf2b38bc51ec",
    "email": "vyha0512@gmail.com",
    "__v": 0
});
  let [activePage, setActivePage] = useState('dashboard');
  let mainContext = { user, setUser, activePage, setActivePage };

  return (
    <MainPageContext.Provider value={mainContext}>
      <GlobalStyles />

      <Router>
        <Routes>
          <Route path="/">
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Account user={user} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/upload"
              element={
                <ProtectedRoute>
                  <Upload user={user} />
                </ProtectedRoute>
              }
            />
            <Route path="/back" element={<Navigate to="/" />} />
            <Route
              path="/edit"
              element={
                <ProtectedRoute>
                  <Edit user={user} />
                </ProtectedRoute>
              }
            />
            <Route path="/search" element={<BlogIndexPage />} />

            <Route path="/search/:search" element={<BlogIndexPage />} />
            <Route path="/search/:search/:min" element={<BlogIndexPage />} />
            <Route
              path="/search/:search/:min/:max"
              element={<BlogIndexPage />}
            />

            <Route
              path="/search/:search/:min/:max/:skip"
              element={<BlogIndexPage />}
            />
            <Route path="/view/:id" element={<TermsOfService />} />

            <Route path="/contact" element={<ContactUs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />

            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route index path="/" element={<SaaSProductLandingPage />} />
          </Route>
        </Routes>
      </Router>
    </MainPageContext.Provider>
  );
}

// export default EventLandingPage;
// export default HotelTravelLandingPage;
// export default AgencyLandingPage;
// export default SaaSProductLandingPage;
// export default RestaurantLandingPage;
// export default ServiceLandingPage;
// export default HostingCloudLandingPage;

// export default LoginPage;
// export default SignupPage;
// export default PricingPage;
// export default AboutUsPage;
// export default ContactUsPage;
// export default BlogIndexPage;
// export default TermsOfServicePage;
// export default PrivacyPolicyPage;

// export default MainLandingPage;
