import React, { useContext, useState } from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container as ContainerBase } from 'components/misc/Layouts';
import tw from 'twin.macro';
import { MainPageContext } from 'App';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import illustration from 'images/undraw_secure_login_pdn4.svg';
import logo from 'images/logo.png';
import googleIconImageSrc from 'images/google-icon.png';
import { ReactComponent as SignUpIcon } from 'feather-icons/dist/icons/user-plus.svg';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg cursor-pointer py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none  px-2 text-sm text-gray-600 tracking-wide font-medium w-[210px] mx-auto bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 `;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-800 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = '/',
  illustrationImageSrc = illustration,
  headingText = 'Sign Up For Treact',
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: 'Sign Up With Google',
      url: 'https://google.com',
    },
  ],
  submitButtonText = 'Sign Up',
  SubmitButtonIcon = SignUpIcon,
  tosUrl = '#',
  privacyPolicyUrl = '#',
  signInUrl = '/login',
}) => {
  const context = useContext(MainPageContext);
  const navigate = useNavigate();

  var emailRegex = /^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  var passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g;

  const [isMissing, setIsMissing] = useState(false);
  const [message, setMessage] = useState('Please fill out the form.');
  const [form, setForm] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!handleCheck()) {
      console.log('a');
      setIsMissing(true);
      return;
    }

    //PENDING HERE: check if the account is already registered on db

    var signupUrl = `http://greenieweb.cyclic.app/auth/dataset/signup`;
    let signupRes = await fetch(signupUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: form.email,
        password: form.password,
        confirmPassword: form.confirmPassword,
      }),
    });
    let jsonSignupRes = await signupRes.json();

    if (jsonSignupRes.generateToken) {
      setIsMissing(false);
      context.setUser(jsonSignupRes.info);
      navigate('/');
    } else {
      setMessage("We don't recognise this email or password.");
      setIsMissing(true);
      return;
    }
  };

  function handleCheck() {
    if (form.email.match(emailRegex) === null) {
      setMessage('Invalid email.');
      return false;
    }
    if (form.password.match(passwordRegex) === null) {
      setMessage(
        'Both password must have minimum length of 8 and contain at least one letter and one number.'
      );
      return false;
    }
    if (form.password !== form.confirmPassword) {
      setMessage('Passwords does not match.');
      return false;
    }
    return true;
  }

  if (
    Object.keys(context.user).length !== 0 &&
    context.user.constructor === Object
  ) {
    navigate('/');
  }
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        console.log(res);
        let data = res.data;
        var loginUrl = `http://greenieweb.cyclic.app/auth/dataset/login`;
        let loginRes = await fetch(loginUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: data.email,
            password: 'google',
          }),
        });
        let jsonLoginRes = await loginRes.json();
        console.log(jsonLoginRes);
        if (jsonLoginRes.generateToken) {
          context.setUser(jsonLoginRes.info);
          navigate('/');
        }
        var baseUrl = `http://greenieweb.cyclic.app/auth/dataset/signup`;
        await fetch(baseUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: data.email,
            password: 'google',
          }),
        });
      } catch (err) {
        console.log(err);
      }
    },
  });

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <SocialButtonsContainer>
                  {socialButtons.map((socialButton, index) => (
                    <SocialButton onClick={() => login()} key={index}>
                      <span className="iconContainer">
                        <img
                          src={socialButton.iconImageSrc}
                          className="icon"
                          alt=""
                        />
                      </span>
                      <span className="text">{socialButton.text}</span>
                    </SocialButton>
                  ))}
                </SocialButtonsContainer>
                <DividerTextContainer>
                  <DividerText>Or Sign up with your e-mail</DividerText>
                </DividerTextContainer>
                <Form onSubmit={handleSubmit}>
                  {isMissing && <p tw="text-red-500">{message}</p>}

                  <Input
                    required
                    id="email"
                    type="email"
                    value={form.email}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                  <Input
                    required
                    id="password"
                    type="password"
                    value={form.pass}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <Input
                    required
                    id="confirmPassword"
                    type="password"
                    value={form.confirmPass}
                    onChange={handleChange}
                    placeholder="Confirm Password"
                  />

                  <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                  <p tw="mt-6 text-xs text-gray-600 text-center">
                    I agree to abide by treact's{' '}
                    <a
                      href={tosUrl}
                      tw="border-b border-gray-500 border-dotted"
                    >
                      Terms of Service
                    </a>{' '}
                    and its{' '}
                    <a
                      href={privacyPolicyUrl}
                      tw="border-b border-gray-500 border-dotted"
                    >
                      Privacy Policy
                    </a>
                  </p>

                  <p tw="mt-8 text-sm text-gray-600 text-center">
                    Already have an account?{' '}
                    <a
                      href={signInUrl}
                      tw="border-b border-gray-500 border-dotted"
                    >
                      Sign In
                    </a>
                  </p>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
