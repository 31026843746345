import React, { useRef, useState } from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Nav from './Nav';
import tw from 'twin.macro'; //eslint-disable-line
import styled from 'styled-components';

import { css } from 'styled-components/macro'; //eslint-disable-line

const Container = tw.div`relative flex-1`;
const Content = tw.div`max-w-screen-xl mx-auto`;

const FormContainer = styled.div`
  ${tw`  text-gray-700 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-800 text-base font-medium tracking-wide border-b-2 py-2 hocus:border-gray-800 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-600`}
    }
  }
`;

const Column = tw.div` flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 text-gray-100 bg-primary-800 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

export default ({user}) => {
  var passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g;

  const formRef = useRef();
  const [isMissing, setIsMissing] = useState(false);
  const [message, setMessage] = useState('Please fill out the form.');
  const [form, setForm] = useState({
    email: user.email,
    oldPass: '',
    newPass: '',
    confirmPass: '',
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(form);
    if (!handleCheck()) {
      setIsMissing(true);
      return; 
    }
    var baseUrl = `http://greenieweb.cyclic.app/auth/dataset/reset`;
    await fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: form.email,
        oldPassword: form.oldPass,
        newPassword: form.newPass,

      }),
    });
    console.log(baseUrl)
  };
  function handleCheck() {
    if (form.oldPass === '' || form.newPass === '' || form.confirmPass === '') {
      setMessage('Please fill out the form.');
      return false;
    }
    if (form.newPass !== form.confirmPass) {
      setMessage('New passwords are not matching.');
      return false;
    }
    if (form.newPass.match(passwordRegex) === null) {
      setMessage(
        'Password must have minimum length of 8 and contain at least one letter and one number.'
      );
      return true;
    }
    return true;
  }
  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  return (
    <AnimationRevealPage>
      <div tw="flex flex-row">
        <Nav />
        <Container>
          <Content>
            <FormContainer>
              <div tw="mx-auto max-w-4xl">
                <h2>Your details</h2>

                <form ref={formRef} onSubmit={handleSubmit}>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="email-input">Email</Label>
                      <Input
                        id="email-input"
                        type="text"
                        name="email"
                        placeholder="E.g. John Doe"
                        value={form.email}
                        onChange={handleChange}
                        disabled
                      />
                    </InputContainer>
                    <p tw="mt-6 text-lg font-bold">Change password</p>
                    {isMissing && <p tw="text-red-500">{message}</p>}
                    <InputContainer>
                      <Label htmlFor="oldPass-input">Old password</Label>
                      <Input
                        value={form.oldPass}
                        onChange={handleChange}
                        id="oldPass-input"
                        type="text"
                        name="oldPass"
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="newPass-input">New password</Label>
                      <Input
                        value={form.newPass}
                        onChange={handleChange}
                        id="newPass-input"
                        type="text"
                        name="newPass"
                      />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="confirmPass-input">
                        Confirm password
                      </Label>
                      <Input
                        id="confirmPass-input"
                        type="text"
                        value={form.confirmPass}
                        onChange={handleChange}
                        name="confirmPass"
                      />
                    </InputContainer>
                  </Column>


                  <SubmitButton type="submit" value="Submit">
                    Update
                  </SubmitButton>
                </form>
              </div>
            </FormContainer>
          </Content>
        </Container>
      </div>
    </AnimationRevealPage>
  );
};
