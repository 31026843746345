import React, { useRef, useState, useEffect, useContext } from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import Nav from './Nav';
import tw from 'twin.macro'; //eslint-disable-line
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { MainPageContext } from 'App';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Container = tw.div`relative flex-1`;
const Content = tw.div`max-w-screen-xl mx-auto`;
const CardAction = tw.button`px-4 py-2 w-32 font-bold text-sm rounded-sm bg-primary-800 text-gray-100 hocus:bg-primary-900 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300`;
const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;

const FormContainer = styled.div`
  ${tw`  text-gray-700 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-800 text-base font-medium tracking-wide border-b-2 py-2 hocus:border-gray-800 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-600`}
    }
  }
`;

export default ({ headingText = 'Terms And Condition' }) => {
  const context = useContext(MainPageContext);
  const formRef = useRef();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false)
  const [isMissing, setIsMissing] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: 'Upload dataset successfully!',
    severity: 'success',
  });
  const [form, setForm] = useState({});
  async function handleSubmit(e) {
    setLoading(true)

    if (!handleCheck()) {
      setIsMissing(true)
    console.log('a')

      return;
    }
    console.log('a')

    var baseUrl = `http://greenieweb.cyclic.app/auth/editData`;

    console.log({
      ...form,
      title: form.title,
      author: form.author,
      usage: form.usage,
      time: form.time,
      location: form.location,
      star: form.star,
      description: form.description,
    })
    let result = await fetch(baseUrl, {
      method: "POST",
      body: JSON.stringify({
        ...form,
        title: form.title,
        author: form.author,
        usage: form.usage,
        time: form.time,
        location: form.location,
        star: form.star,
        description: form.description,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    });
   await result.json();
    refresh()

    console.log(form);
  };
  function handleCheck() {
    console.log(form);
    if (
      form.title === '' ||
      form.author === '' ||
      form.description === '' ||
      form.image === '' ||
      form.email === '' ||
      form.price === ''
    ) {
      setAlert((oldState) => {
        return {
          ...oldState,
          open: true,
          severity: 'error',
          message: 'Please fill out the form.',
        };
      });
      return false;
    }

    let count = 0;
    if (form.demographic === '') count++;
    if (form.historical === '') count++;
    if (form.geographic === '') count++;
    if (form.other === '') count++;
    console.log(count);
    if (count > 3) {
      setAlert((oldState) => {
        return {
          ...oldState,
          open: true,
          severity: 'error',
          message: 'Please fill out 3 of the statistics.',
        };
      });
      return false;
    }
    if (isNaN(form.price)) {
      setAlert((oldState) => {
        return {
          ...oldState,
          open: true,
          severity: 'error',
          message: 'Price must be a number.',
        };
      });
      return false;
    }
    return true;
  }
  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setForm({
      ...form,
      [name]: value,
    });
  };
async function handleDelete(){
  setLoading(true)
  var baseUrl = `http://greenieweb.cyclic.app/auth/deleteData`;
  let result = await fetch(baseUrl, {
    method: "POST",
    body: JSON.stringify({
      dataset: form.currentDataset
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    }
  });
  await result.json();
  refresh()
  setLoading(false)
}

function refresh(){
  getData()
  setOpen(false)
  setLoading(false)
  setForm({
    title: '',
    author: '',
    demographic: '',
    historical: '',
    geographic: '',
    other: '',
    description: '',
    currentDataset: {}
  })
}

  const handleClickOpen = (data) => {
    setOpen(true);
    console.log(data)
    setForm(data);
  };

  const handleClose = () => {
    setLoading(false)
    setOpen(false);
  };
  let [cards, setCards] = useState([]);
  async function getData() {
    var baseUrl = `http://greenieweb.cyclic.app/auth/searchId/${context.user._id}`;
    let result = await fetch(baseUrl);
    let resJson = await result.json();
    let newCards = resJson.map((res, index) => {
      return (
        <div
          key={index}
          tw="flex flex-row border border-black rounded-sm justify-between p-4"
        >
          <div tw="flex flex-row items-center">
            <div tw="min-w-[30px]">{index + 1}.</div>
            <div tw="line-clamp-2">{res.title}</div>
          </div>
          <CardAction
            onClick={() => {
              handleClickOpen(res);
            }}
          >
            Edit Data
          </CardAction>
        </div>
      );
    });
    setCards(newCards);
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimationRevealPage>
      <div tw="flex flex-row gap-16">
      <Backdrop
        sx={{ color: '#fa8e30', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={loading}
      >
        <CircularProgress color="success" />
      </Backdrop>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

        <Nav />
        <Container>
          <Content>
            <FormContainer ref={formRef}  >
              <div tw="mx-auto max-w-4xl">
                <h2>List datasets to edit</h2>

                <div tw="flex flex-col gap-4">{cards}</div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                      event.preventDefault();
                      handleSubmit(event)
                    },
                  }}
                >
                  <DialogTitle>Edit datasets</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      To edit to this dataset or remove the dataset (only basic
                      information about the dataset can be change).
                    </DialogContentText>
                    <FormContainer>
                      <div tw="mx-auto max-w-4xl">
                        {isMissing && (
                          <p tw="text-red-500">Please fill out the form.</p>
                        )}

                        <TwoColumn>
                          <Column>
                            <InputContainer>
                              <Label htmlFor="title-input">Title*</Label>
                              <Input
                                id="title-input"
                                type="text"
                                name="title"
                                placeholder="Title of the dataset"
                                value={form.title}
                                onChange={handleChange}
                              />
                            </InputContainer>
                            <InputContainer>
                              <Label htmlFor="usage-input">
                                Demographic Statistics**
                              </Label>
                              <Input
                                value={form.usage}
                                onChange={handleChange}
                                id="usage-input"
                                type="text"
                                name="usage"
                                placeholder="Related to structure of population."
                              />
                            </InputContainer>
                            <InputContainer>
                              <Label htmlFor="time-input">
                                Historical Statistics**
                              </Label>
                              <Input
                                value={form.time}
                                onChange={handleChange}
                                id="time-input"
                                type="text"
                                name="time"
                                placeholder="Related to its history."
                              />
                            </InputContainer>
                            <InputContainer>
                              <Label htmlFor="location-input">
                                Geographic Statistics**
                              </Label>
                              <Input
                                id="location-input"
                                type="text"
                                value={form.location}
                                onChange={handleChange}
                                name="location"
                                placeholder="Location that the it target."
                              />
                            </InputContainer>
                          </Column>
                          <Column>
                            <InputContainer>
                              <Label htmlFor="author-input">Author*</Label>
                              <Input
                                id="author-input"
                                type="text"
                                name="author"
                                value={form.author}
                                onChange={handleChange}
                                placeholder="Author of the dataset"
                              />
                            </InputContainer>
                            <InputContainer>
                              <Label htmlFor="star-input">
                                Other Statistics**
                              </Label>
                              <Input
                                id="star-input"
                                type="text"
                                value={form.star}
                                onChange={handleChange}
                                name="star"
                                placeholder="Other statistics."
                              />
                            </InputContainer>
                            <InputContainer tw="flex-1">
                              <Label htmlFor="description-input">
                                Description*
                              </Label>
                              <TextArea
                                id="description-input"
                                value={form.description}
                                onChange={handleChange}
                                name="description"
                                placeholder="Detailed description about the datatset."
                              />
                            </InputContainer>
                          </Column>
                        </TwoColumn>
                      </div>
                    </FormContainer>
                  </DialogContent>
                  <DialogActions>
                    <Button color="error" onClick={handleDelete}>
                      Delete
                    </Button>
                    <Button type='submit' >Change</Button>
                    <Button onClick={handleClose}>Cancel</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </FormContainer>
          </Content>
        </Container>
      </div>
    </AnimationRevealPage>
  );
};
