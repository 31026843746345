import React from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts';
import tw from 'twin.macro';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import Header from 'components/headers/light.js';
import Footer from "components/footers/MiniCenteredFooter";
import { SectionHeading } from 'components/misc/Headings';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ReactComponent as TrendingIcon } from 'images/arrow-trend-up-solid.svg';
import { ReactComponent as LocationIcon } from 'images/location-dot-solid.svg';
import { ReactComponent as UserIcon } from 'images/user-solid.svg';
import { ReactComponent as TimeIcon } from 'images/clock-solid.svg';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';

const HeadingRow = tw.div`flex flex-col mb-10`;
const Heading = tw(SectionHeading)`text-left text-gray-900 `;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full md:w-[250px] mt-4`;

const FeatureSection = tw.div` flex items-center gap-3`;
const Description = tw.div` mt-4`;

export default () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let [data, setData] = useState({});
  async function getData() {
    let result = await fetch(
      `http://greenieweb.cyclic.app/auth/viewId/${id}`
    );
    let resJson = await result.json();
    console.log(resJson);
    setData(resJson[0]);
  }
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          {Object.keys(data).length !== 0 && data.constructor === Object ? (
            <>
              <HeadingRow>
                <Heading>{data.title}</Heading>
                <p>{data.author}</p>
              </HeadingRow>
              <Text>
                {data.location ? (
                  <FeatureSection>
                    <LocationIcon /> {data.location}
                  </FeatureSection>
                ) : (
                  <></>
                )}
                {data.star ? (
                  <FeatureSection>
                    <TrendingIcon /> {data.star}
                  </FeatureSection>
                ) : (
                  <></>
                )}
                {data.usage ? (
                  <FeatureSection>
                    <UserIcon /> {data.usage}
                  </FeatureSection>
                ) : (
                  <></>
                )}
                {data.time ? (
                  <FeatureSection>
                    <TimeIcon /> {data.time}
                  </FeatureSection>
                ) : (
                  <></>
                )}
                <Description>{data.description}</Description>

                <CardAction
                  onClick={() => {
                    navigate('/contact');
                  }}
                >
                  Request details
                </CardAction>
              </Text>
            </>
          ) : (
            <></>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
