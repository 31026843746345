import React from "react";
import tw from "twin.macro";//eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
// import Features from "components/features/ThreeColCenteredStatsPrimaryBackground";

import Contact from "components/forms/SimpleContactUs"
import Cards from 'components/cards/TabCardGrid.js'
import Footer from "components/footers/MiniCenteredFooter";


export default  () => {

  // const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  // const HighlightedText = tw.span`text-primary-500`;
  console.log(process.env.REACT_APP_PRO_MODE)
  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={false} />
      {/* <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
      /> */}

      <Cards />

<Contact />
      <Footer />
    </AnimationRevealPage>
  );
}
