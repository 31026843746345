import React from "react";
import tw from "twin.macro";//eslint-disable-line
import { navigationList } from "constant";
import {css} from "styled-components/macro"; //eslint-disable-line
import { Link } from "react-router-dom";

export default () => {


  let navBar = navigationList.map((page) => {
    return (
      <Link key={page.id} to={`/${page.id}`}>
        <div
          tw="flex flex-row items-center gap-3 text-black"
          id={`${page.id}-section`}
        >
          {page.icon}
          <span>{page.label}</span>
        </div>
      </Link>
    );
  });
  return(

        <div tw="flex flex-col gap-6 py-8">{navBar}</div>

  )
}

