import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { MainPageContext } from 'App';

function ProtectedRoute({ children }) {
	const context = useContext(MainPageContext)
console.log(context.user)
	return Object.keys(context.user).length === 0 ? <Navigate to="/login" /> : children;
}

export default ProtectedRoute;
