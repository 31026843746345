import React, { useContext, useEffect, useRef, useState } from "react";
import { MainPageContext } from "App";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/undraw_two_factor_authentication_namy.svg";
import logo from "images/logo.png";
import googleIconImageSrc from "images/google-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.div`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 cursor-pointer border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none  px-2 text-sm text-gray-600 tracking-wide font-medium w-[210px] mx-auto bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 `;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-800 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Sign In To Datasetcity",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign In With Google",
      url: "https://google.com",
    },
  ],
  submitButtonText = "Sign In",
  SubmitButtonIcon = LoginIcon,
  signupUrl = "/signup",
}) => {
  const formRef = useRef();
  const [isMissing, setIsMissing] = useState(false);
  const [message, setMessage] = useState("Please fill out the form.");
  const [form, setForm] = useState({
    email: "",
    pass: "",
  });
  var emailRegex = /^[\w.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  var passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g;

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  const context = useContext(MainPageContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      Object.keys(context.user).length !== 0 &&
      context.user.constructor === Object
    ) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
            },
          }
        );
        let data = res.data;
        var loginUrl = `http://greenieweb.cyclic.app/auth/dataset/login`;
        let loginRes = await fetch(loginUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: data.email,
            password: "google",
          }),
        });
        let jsonLoginRes = await loginRes.json();
        if (jsonLoginRes.generateToken) {
          context.setUser(jsonLoginRes.info);
          navigate("/");
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  async function handleSubmit(e) {
    e.preventDefault();
    if (!handleCheck()) {
      console.log("a");
      setIsMissing(true);
      return;
    }
    var loginUrl = `http://greenieweb.cyclic.app/auth/dataset/login`;
    let loginRes = await fetch(loginUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: form.email,
        password: form.pass,
      }),
    });
    let jsonLoginRes = await loginRes.json();
    if (jsonLoginRes.generateToken) {
      context.setUser(jsonLoginRes.info);
      navigate("/");
    }
    else{
      setMessage("We don't recognise this email or password.");
      setIsMissing(true)
      return
    }

  }
  function handleCheck() {
    if (form.email === "" || form.pass === "") {
      setMessage("Please fill out the form.");
      return false;
    }
    if (form.email.match(emailRegex) === null) {
      setMessage("Invalid email.");
      return false;
    }
    if (form.pass.match(passwordRegex) === null) {
      setMessage(
        "Password must have minimum length of 8 and contain at least one letter and one number."
      );
      return false;
    }
    return true;
  }
  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <SocialButtonsContainer>
                  {socialButtons.map((socialButton, index) => (
                    <SocialButton
                      onClick={() => login()}
                      key={index}
                      href={socialButton.url}
                    >
                      <span className="iconContainer">
                        <img
                          src={socialButton.iconImageSrc}
                          className="icon"
                          alt=""
                        />
                      </span>
                      <span className="text">{socialButton.text}</span>
                    </SocialButton>
                  ))}
                </SocialButtonsContainer>
                <DividerTextContainer>
                  <DividerText>Or Sign in with your e-mail</DividerText>
                </DividerTextContainer>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  {isMissing && <p tw="text-red-500">{message}</p>}

                  <Input
                    type="email"
                    value={form.email}
                    onChange={handleChange}
                    placeholder="Email"
                  />
                  <Input
                    type="password"
                    value={form.pass}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  <SubmitButton type="submit">
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                </Form>
                {/* <p tw="mt-6 text-xs text-gray-600 text-center">
                <a href={forgotPasswordUrl} tw="border-b border-gray-500 border-dotted">
                  Forgot Password ?
                </a>
              </p> */}
                <p tw="mt-8 text-sm text-gray-600 text-center">
                  Dont have an account?{" "}
                  <a
                    href={signupUrl}
                    tw="border-b border-gray-500 border-dotted"
                  >
                    Sign Up
                  </a>
                </p>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
