import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts.js';
import { SectionHeading } from 'components/misc/Headings.js';
import { PrimaryButton as PrimaryButtonBase } from 'components/misc/Buttons.js';
import { ReactComponent as SvgDecoratorBlob1 } from 'images/svg-decorator-blob-5.svg';
import { ReactComponent as SvgDecoratorBlob2 } from 'images/svg-decorator-blob-7.svg';
import { ReactComponent as TrendingIcon } from 'feather-icons/dist/icons/trending-up.svg';
import { ReactComponent as StarIcon } from 'feather-icons/dist/icons/star.svg';
import { ReactComponent as UserIcon } from 'feather-icons/dist/icons/user.svg';
import { ReactComponent as GlobeIcon } from 'feather-icons/dist/icons/globe.svg';

const HeaderRow = tw.div`flex justify-center text-center items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap gap-6 justify-center`;
const Card = tw.div`sm:w-[360px] shadow-lg border-[1px] rounded-md p-4`;

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between  items-center`;
const CardType = tw.div`text-primary-800 font-bold text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold text-gray-800 text-lg`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardDescription = tw.div`px-3 text-gray-600 mt-4 line-clamp-3`;

const CardMeta = styled.div`
  ${tw`flex flex-col flex-wrap justify-between sm:items-start font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4 gap-2 `}
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-4`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({ heading = 'Checkout popular data products' }) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */ const navigate = useNavigate();
  let [cards, setCards] = useState([]);
  async function getData() {
    let result = await fetch('https://greenieweb.cyclic.app/auth/getData');
    let resJson = await result.json();
    setCards(resJson);
  }
  useEffect(() => {
    getData();
  }, []);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
        </HeaderRow>
        <TabContent>
          {cards.map((card) => (
            <Card key={card._id}>
              <CardText>
                <CardHeader>
                  <CardType>{card.author}</CardType>
                  <CardPrice>
                    <CardPriceAmount>
                      {formatter.format(card.price)}
                    </CardPriceAmount>
                  </CardPrice>
                </CardHeader>
                <CardTitle>{card.title}</CardTitle>
                <CardMeta>
                  {card.location ? (
                    <CardMetaFeature>
                      <div tw="w-6">
                        <GlobeIcon />
                      </div>
                      <div tw="line-clamp-2">{card.location}</div>
                    </CardMetaFeature>
                  ) : (
                    <></>
                  )}
                  {card.star && !(card.location && card.usage && card.time) ? (
                    <CardMetaFeature>
                      <div tw="w-6">
                        <StarIcon />
                      </div>
                      <div tw="line-clamp-2">{card.star}</div>
                    </CardMetaFeature>
                  ) : (
                    <></>
                  )}
                  {card.usage ? (
                    <CardMetaFeature>
                      <div tw="w-6">
                        <UserIcon />
                      </div>
                      <div tw="line-clamp-2">{card.usage}</div>
                    </CardMetaFeature>
                  ) : (
                    <></>
                  )}
                  {card.time ? (
                    <CardMetaFeature>
                      <div tw="w-6">
                        <TrendingIcon />
                      </div>
                      <div tw="line-clamp-2">{card.time}</div>
                    </CardMetaFeature>
                  ) : (
                    <></>
                  )}
                </CardMeta>
                <CardDescription>{card.description}</CardDescription>
                <CardAction
                  onClick={() => {
                    navigate(`/view/${card._id}`);
                  }}
                >
                  View Data
                </CardAction>
              </CardText>
            </Card>
          ))}
        </TabContent>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
