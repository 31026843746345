import React from 'react';
import { motion } from 'framer-motion';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { css } from 'styled-components/macro'; //eslint-disable-line
import { useContext } from 'react';
import useAnimatedNavToggler from '../../helpers/useAnimatedNavToggler.js';
import { MainPageContext } from 'App.js';
import { categories } from 'constant/index.js';
import logo from '../../images/logo.png';
import { ReactComponent as MenuIcon } from 'feather-icons/dist/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg';
import Select from 'react-select';

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto 
`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-700 hocus:text-primary-700
`;
export const categoryLink = tw`

`;
export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-700 text-gray-100
  hocus:bg-primary-800 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = 'lg',
}) => {
  const categoriesLinks = categories.slice(0, 4).map((category) => {
    return (
      <a
        css={
          true &&
          tw` lg:!ml-0   text-lg lg:text-sm lg:!ml-0 
        font-semibold tracking-wide transition duration-300 hocus:text-primary-700 hover:underline cursor-pointer`
        }
        href={`/search/${category.value}`}
        key={category.value}
      >
        {category.label}
      </a>
    );
  });

  const defaultLinks = [
    <div
      css={
        true && tw`hidden lg:!flex items-center justify-center	gap-10 flex-row `
      }
    >
      {categoriesLinks}
    </div>,
    <NavLinks key={1}>
      <Select
        className="basic-single"
        classNamePrefix="select"
        name="category"
        value={{ label: 'Browse Categories', value: 'browse' }}
        options={categories}
        css={true && tw`lg:!hidden `}
        styles={{
          option: (baseStyles, state) => ({
            ...baseStyles,
            color: 'black',
            backgroundColor: state.isSelected
              ? 'hsl(147, 74%, 63%)'
              : state.isFocused
              ? 'hsl(0, 0%, 85%)'
              : 'white',
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: 216,
            cursor: 'pointer',
            borderWidth: 1,
            borderColor: '#777777',
          }),
          container: (baseStyles, state) => ({
            ...baseStyles,
            alignItems: 'center',
            display: 'flex',
          }),
        }}
      />
      <NavLink href="/login" tw="lg:ml-12!">
        Login
      </NavLink>
      <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} href="/signup">
        Sign Up
      </PrimaryLink>
    </NavLinks>,
  ];

  const dashboardLinks = [
    <div
      css={
        true && tw`hidden lg:!flex items-center justify-center	gap-10 flex-row `
      }
    >
      {categoriesLinks}
    </div>,
    <NavLinks key={1}>
      <Select
        className="basic-single"
        classNamePrefix="select"
        name="category"
        value={{ label: 'Browse Categories', value: 'browse' }}
        options={categories}
        css={true && tw`lg:!hidden `}
        styles={{
          option: (baseStyles, state) => ({
            ...baseStyles,
            color: 'black',
            backgroundColor: state.isSelected
              ? 'hsl(147, 74%, 63%)'
              : state.isFocused
              ? 'hsl(0, 0%, 85%)'
              : 'white',
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: 216,
            cursor: 'pointer',
            borderWidth: 1,
            borderColor: '#777777',
          }),
          container: (baseStyles, state) => ({
            ...baseStyles,
            alignItems: 'center',
            display: 'flex',
          }),
        }}
      />
      <Link
        key="dashboard"
        to="/dashboard"
        css={
          true &&
          tw`rounded-full lg:mx-0
  px-8 py-3 rounded bg-primary-700 text-gray-100
  hocus:bg-primary-800 hocus:text-gray-200 focus:shadow-outline
  border-b-0`
        }
        href="/dashboard"
      >
        Dashboard
      </Link>
    </NavLinks>,
  ];

  // eslint-disable-next-line no-unused-vars
  const context = useContext(MainPageContext);

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo} alt="logo" />
      Dataset City
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;
  console.log(context.user);

  return (
    <Header className={className || 'header-light'}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {Object.keys(context.user).length !== 0 &&
        context.user.constructor === Object
          ? dashboardLinks
          : links}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: '150%', display: 'none' }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {Object.keys(context.user).length !== 0 &&
          context.user.constructor === Object
            ? dashboardLinks
            : links}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? 'open' : 'closed'}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
