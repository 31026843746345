
import { ReactComponent as Account } from "images/account_circle_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as Upload } from "images/drive_folder_upload_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as Edit } from "images/edit_FILL0_wght400_GRAD0_opsz24.svg";
import { ReactComponent as Back } from "images/arrow_back_FILL0_wght400_GRAD0_opsz24.svg";
let navigationList = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    icon:<Account />,
  },
  {
    id: 'upload',
    label: 'Upload Dataset',
    icon: <Upload />
  },
  {
    id: 'edit',
    label: 'Edit Dataset',
    icon: <Edit />,
  },
  {
    id: 'back',
    label: 'Back',
    icon: <Back />,
  },
];

export {navigationList}