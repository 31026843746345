import React, { useState, useEffect } from 'react';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { Container, ContentWithPaddingXl } from 'components/misc/Layouts';
import tw from 'twin.macro';
import { css } from "styled-components/macro"; //eslint-disable-line

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Header from 'components/headers/light.js';
import Footer from 'components/footers/MiniCenteredFooter.js';
import { SectionHeading } from 'components/misc/Headings';
import { useParams } from 'react-router-dom';
import { motion } from "framer-motion";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as TrendingIcon } from 'feather-icons/dist/icons/trending-up.svg';
import { ReactComponent as StarIcon } from 'feather-icons/dist/icons/star.svg';
import { ReactComponent as UserIcon } from 'feather-icons/dist/icons/user.svg';
import { ReactComponent as GlobeIcon } from 'feather-icons/dist/icons/globe.svg';
const HeadingRow = tw.div`text-center mb-6`;
const Heading = tw(SectionHeading)`text-gray-900`;


const TabContent = tw(motion.div)`mt-6 flex flex-wrap gap-6 justify-center`;
const Card = tw.div`sm:w-[360px] shadow-lg border-[1px] rounded-md p-4`;


const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between  items-center`;
const CardType = tw.div`text-primary-800 font-bold text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold text-gray-800 text-lg`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardDescription = tw.div`px-3 text-gray-600 mt-4 line-clamp-3`;

const CardMeta = styled.div`
  ${tw`flex flex-col flex-wrap justify-between sm:items-start font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4 gap-2 `}
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-4`;
const Input = tw.input`border-2 px-5 py-3 xl:w-[280px] lg:w-[230px] md:w-[160px] sm:w-[130px] w-[100px] rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-800`

const SubmitButton = tw(PrimaryButtonBase)` xl:w-[280px]lg:w-[230px] md:w-[160px] sm:w-[130px] w-[100px] `

const SearchDiv = tw.form`flex gap-2 flex-row sm:justify-around flex-wrap justify-between`
export default ({
  headingText = 'Search result',

}) => {
  const { search, min, max, skip } = useParams();
  const navigate = useNavigate()
  let [newMin, setNewMin] = useState()
  let [newMax, setNewMax] = useState()
  let [newSearch, setNewSearch] = useState('')
  function handleSubmit(e){
    let priceMin= newMin || 0
    let priceMax= newMax || 99999999999
    console.log(newSearch)
    navigate(`/search/${newSearch}/${priceMin}/${priceMax}`)
    
  }
  let [cards, setCards] = useState([]);
  async function getData() {
    let searchRes = search || ''
              let priceMin= min || 0
              let priceMax= max || 99999999999
              let limit= 10
              let cardSkip = skip || 0

    var baseUrl = `http://greenieweb.cyclic.app/auth/searchData/${searchRes}/${priceMin}/${priceMax}/${cardSkip*10}/${limit}`;
    if (searchRes === ''){
      baseUrl = 'https://greenieweb.cyclic.app/auth/getData'
    }
    let result = await fetch(baseUrl);
    let resJson = await result.json();
    console.log(resJson)
    setCards(resJson);
  }
  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <SearchDiv onSubmit={(e) =>{
            handleSubmit(e)
          }}>
          <Input type="number" value={newMin} onChange={(e) =>{
                setNewMin(e.target.value)
              }} name="priceMin" placeholder="Min Price" />
          <Input type="number" value={newMax} onChange={(e) =>{
                setNewMax(e.target.value)
              }} name="priceMax" placeholder="Max Price" />
<Input required type="text" value={newSearch} onChange={(e) =>{
                setNewSearch(e.target.value)
              }} placeholder="Search for data" />
              <SubmitButton  type="submit">Search</SubmitButton>

          </SearchDiv>
          <TabContent>
          {cards.map((card) => (
            <Card key={card._id}>
              <CardText>
                <CardHeader>
                  <CardType>{card.author}</CardType>
                  <CardPrice>
                    <CardPriceAmount>
                      {formatter.format(card.price)}
                    </CardPriceAmount>
                  </CardPrice>
                </CardHeader>
                <CardTitle>{card.title}</CardTitle>
                <CardMeta>
                  {card.location ? (
                    <CardMetaFeature>
                      <div tw="w-6">
                        <GlobeIcon />
                      </div>
                      <div tw="line-clamp-2">{card.location}</div>
                    </CardMetaFeature>
                  ) : (
                    <></>
                  )}
                  {card.star && !(card.location && card.usage && card.time) ? (
                    <CardMetaFeature>
                      <div tw="w-6">
                        <StarIcon />
                      </div>
                      <div tw="line-clamp-2">{card.star}</div>
                    </CardMetaFeature>
                  ) : (
                    <></>
                  )}
                  {card.usage ? (
                    <CardMetaFeature>
                      <div tw="w-6">
                        <UserIcon />
                      </div>
                      <div tw="line-clamp-2">{card.usage}</div>
                    </CardMetaFeature>
                  ) : (
                    <></>
                  )}
                  {card.time ? (
                    <CardMetaFeature>
                      <div tw="w-6">
                        <TrendingIcon />
                      </div>
                      <div tw="line-clamp-2">{card.time}</div>
                    </CardMetaFeature>
                  ) : (
                    <></>
                  )}
                </CardMeta>
                <CardDescription>{card.description}</CardDescription>
                <CardAction
                  onClick={() => {
                    navigate(`/view/${card._id}`);
                  }}
                >
                  View Data
                </CardAction>
              </CardText>
            </Card>
          ))}
</TabContent>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};

