let categories = [
  {
    value: 'financial',
    label: 'Financial Data'
  },{
    value: 'consumer',
    label: 'Consumer Data'
  },{
    value: 'weather',
    label: 'Weather Data'
  }
  ,{
    value: 'web',
    label: 'Web Data'
  },{
    value: 'legal',
    label: 'Legal Data'
  },{
    value: 'entertainment',
    label: 'Entertainment Data'
  },
]

export{
  categories
}