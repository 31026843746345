import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import { GoogleOAuthProvider } from '@react-oauth/google';

Modal.setAppElement("#root");

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <GoogleOAuthProvider clientId="431211984151-l3vkkf2g44f0fikgeuvi5ceheig47dh3.apps.googleusercontent.com">

<App />
</GoogleOAuthProvider>

);